import { UserAudioTrack, UserMedia } from "@liveswitch/sdk";
import type LiveSwitchUserMedia from "./LiveSwitchUserMedia";

export default class CanvasUserMedia extends UserMedia {
    private readonly _originalUserMedia: LiveSwitchUserMedia;
    private _canvasStream: MediaStream | null;
    private _started: boolean = false;
    
    public constructor(audio: boolean, video: boolean, canvasStream: MediaStream, originalUserMedia: LiveSwitchUserMedia) {
        super(audio, video);
        this._canvasStream = canvasStream;
        this._originalUserMedia = originalUserMedia;
        this.initializeStreamHandlers();
    }

    protected async getStream(constraints: MediaStreamConstraints) {
        const stream: MediaStream = new MediaStream();
        stream.addTrack(this._originalUserMedia?.stream?.getAudioTracks()[0]);
        const videoTrack = this._canvasStream!.getVideoTracks()[0]
        stream.addTrack(videoTrack);
        return stream;
    }

    override get stream(): MediaStream {
        return this._canvasStream!;
    }

    public async syncVideoMutedState(){
        const canvasVideoTrack = this._canvasStream!.getVideoTracks()[0]
        const lsVideoTrack = this._originalUserMedia!.videoTrack
        if(canvasVideoTrack.muted){
            await lsVideoTrack.mute()
        }else{
            await lsVideoTrack.unmute()
        }
    }

    public async setVideoDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await this._originalUserMedia.setVideoDevice(deviceId, required);
    }

    public async setAudioDevice(deviceId?: string | undefined, required?: boolean | undefined): Promise<void> {
        await this._originalUserMedia.setAudioDevice(deviceId, required);
    }

    public get audioTrack(): UserAudioTrack {
        return this._originalUserMedia.audioTrack;
    }
    public get requestedVideoDeviceId(): string {
        return this._originalUserMedia.requestedVideoDeviceId;
    }

    public get requestedAudioDeviceId(): string {
        return this._originalUserMedia.requestedAudioDeviceId;
    }

    public get audioDeviceId(): string {
        return this._originalUserMedia.audioDeviceId;
    }

    public get videoDeviceId(): string {
        return this._originalUserMedia.videoDeviceId;
    }

    public get videoFacingMode(): string {
        return this._originalUserMedia.videoFacingMode;
    }

    // for some reason, this isstarted flag is required for Safari,
    // or we get no data from the canvas, i'm assuming because of an order of operations issue
    // where we don't fire an event properly from this canvas user media (we have a number of places
    // where we check for "isstarted" and if it's not set, wait for an event)
    // for now, i'm setting this flag back, but i'm worried that this is going to cause issues
    // elsewhere...
    public get isStarted(): boolean {
        //return this._originalUserMedia.isStarted;
        return this._started
    }

    public set isStarted(started: boolean) {
        this._started = started;
       
    }

    public async stop(): Promise<void> {
        await this.audioTrack.stop();
        await this.videoTrack.stop();
        await this._originalUserMedia.stop();

        if (this._canvasStream) {
            this._canvasStream.getTracks().forEach((track) => {
                track.stop();
            })

            this._canvasStream = null;
        }
    }

    private initializeStreamHandlers() {
        //this.videoTrack.reload.prototype = () => { console.log('reloading track') };
    }
}