<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue'
    import type MeetingHandler from "@/classes/MeetingHandler";
    import type { PropType } from 'vue'
    import { UserMedia } from '@liveswitch/sdk';
    import useHelpers from '@/composables/useHelpers';
	import PermissionsHelper from "@/components/modals/PermissionsHelper.vue";

    const isGettingPermission = ref(false)
    const triedToGetPermission = ref(false)
    const gotPermission = ref(false)

    const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		}
    })

    const steps = {
        'noSupport': -1,
        'detectingSupport': 0,
        'needsPermission': 1,
        'gettingPermission': 2,
        'permissionDenied': 3,
        'ready': 4
    }

    const step = computed(() => {

        // wait to load
        if(!props.meetingHandler.detectRtcLoaded){
            return steps.detectingSupport
        }
        

        let dt = props.meetingHandler.detectRtc
        if(!dt.isWebRTCSupported){
            return steps.noSupport
        }

        // while waiting for permissions
        if(isGettingPermission.value){
            return steps.gettingPermission
        }

        if(triedToGetPermission.value){
            if(gotPermission.value){
                return steps.ready
            }else{
                return steps.permissionDenied
            }
        }

        // if we have a mic, but no permission to the mic
        // OR we have a camera, but no permission to the camera, 
        // we need to ask for permission

        // JEROD: we are disabling this for the current release (Mar 27 2024), and just always checking for permissions.
        // this means you must have a camera and mic to join a meeting

        //if(dt.hasMicrophone && !dt.isWebsiteHasMicrophonePermissions){
        if(!dt.isWebsiteHasMicrophonePermissions){
            return steps.needsPermission
        }

        //if(dt.hasWebcam && !dt.isWebsiteHasWebcamPermissions){
        if(!dt.isWebsiteHasWebcamPermissions){
            return steps.needsPermission
        }

        return steps.ready
    })


    const getPermissions = async () => {
        isGettingPermission.value = true
        let dt = props.meetingHandler.detectRtc
        // SEE ABOVE for comment on why we always get mic AND camera for now
        let lm = new UserMedia(true, true) //dt.hasMicrophone, dt.hasWebcam);
        try{
            await lm.start()
        
            // if we got an audio track at least, we can go ahead and start
            if(lm.audioTrack){
                gotPermission.value = true
            }else{
                gotPermission.value = false
            }
        }catch(e){
            console.error(e)
            gotPermission.value = false
        }finally{
            await lm.stop();
            isGettingPermission.value = false
            triedToGetPermission.value = true
            localStorage.setItem('safariPermissionAuthorized', 'true')
        }
    }

    const stepName = computed(() => {
        return Object.keys(steps).find(key => (steps as any)[key] === step.value)
    })

    watch(step, () =>{
        console.log('step changed:', step.value)
        if(step.value == steps.ready){
            props.meetingHandler.setPrejoinCheckCompleted()
        }else if(step.value == steps.permissionDenied){
            showPermissionsHelper.value = true
        }
    })
	
    const debug = ref(false)
    const showPermissionsHelper = ref(false);
    
    onMounted(() => {
        // on first launch, on safari, we want to show the extra "allow" button
        // but on subsequent launches, we don't want to show it; but iOS doesn't remember
        // so we have to remember for it
        if(useHelpers().isSafari() && localStorage.getItem('safariPermissionAuthorized') == 'true'){
            getPermissions()
        }
    })
</script>
<template>
    <div class="prejoin-container" v-if="step !== steps.ready && step != steps.detectingSupport">
        <PermissionsHelper v-model:show="showPermissionsHelper" :additional-instructions="props.meetingHandler.getErrorMessageWithDialIn()"></PermissionsHelper>

        <div class="prejoin-container-inner"
            :class="stepName">
            <div class="image-container">
                <img style="display:none" v-if="step == steps.needsPermission || step == steps.detectingSupport" src="https://www.gstatic.com/meet/permissions_flow_intro_d9412887c3318bdd2bbbd74faa821736.svg">
                <img style="display:none" v-if="step == steps.gettingPermission" src="https://www.gstatic.com/meet/permissions_flow_allow_ltr_478239240996514edc5a278a3a08fa6f.svg">
            </div>
            <div class="instructions" v-if="step == steps.detectingSupport">
                <h2>Detecting settings...</h2>
            </div>

            <div class="instructions" v-if="step == steps.needsPermission">
                <h2>Do you want people to see and hear you in the meeting?</h2>
                <span>You can still turn off your microphone and camera at any time.</span>
                <div class="actions">
                    <button class="btn btn-primary" @click="getPermissions()">
                        <span>Allow microphone and camera</span>
                    </button>
                </div>
            </div>

            <div class="instructions" v-if="step == steps.noSupport">
                <h2>Your browser doesn't support this application.</h2>
                <span>Please try a different browser.</span>
            </div>
            
            <div class="instructions" v-if="step == steps.gettingPermission">
                <h2>Checking Permissions...</h2>
                <span>Click <strong>Allow</strong> if prompted. You can still turn off your microphone and camera anytime you want.</span>
            </div>

            <div class="instructions" v-if="debug && props.meetingHandler.detectRtcLoaded">
                <ul>
                    <li>{{ props.meetingHandler.detectRtc?.browser }}</li>
                    <li>Microphone: {{ props.meetingHandler.detectRtc?.hasMicrophone }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.prejoin-container{
    display: flex;
    height: 100dvh;
    width: 100%;
    position: absolute;
    left:0;
    top:0;
    background-color:#20212499;
    color: #333;

}
.prejoin-container-inner{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 8px;
    z-index: 10;
    background: white;
    padding:48px;
    text-align: center;
}

.safari .prejoin-container-inner.gettingPermission {
    margin-bottom: 12px;
}


.prejoin-container img{
    width:230px;
    height:230px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (orientation: landscape) and (max-height: 420px){
    .prejoin-container-inner{
        flex-direction: row;
    }
    .prejoin-container img{
        width:auto;
        height:auto;
        padding-right:16px;
    }
}

h2{
    font-weight: 300;
}
.instructions span{
    font-size: 14px;
}
.actions{
    margin-top: 32px;
}
button{
    height:45px;
    width: 245px;
}
</style>