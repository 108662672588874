import { computed, reactive, ref, watch } from "vue";
import type { Attendee, AttendeeRole, ChatChannel, ChatMessage, Meeting } from "@liveswitch/sdk";
import useHelpers from "./useHelpers";
import type MessageSendFileOptions from "@liveswitch/sdk/chat/models/MessageSendFileOptions";
import type Message from "@liveswitch/sdk/chat/Message";
import useEventBus from "./useEventBus";
import type { EmojiReaction } from "@/classes/EmojiReaction";
import * as DOMPurify from "dompurify";

// Constants
export const activeChatChannelId = computed(() => activeChannel.value?.id ?? "default");
export const canToggleMeetingChat = computed(() => role.value === "HOST" || role.value === "MODERATOR");

export const hasAnyNewMessage = computed(() => {
	for (const key in channelHasNewMessageMap) {
		if (channelHasNewMessageMap[key] > 0) {
			return true;
		}
	}
	return false;
});

export const newMessageCount = computed(() => {
	let total = 0;

	for (const key in channelHasNewMessageMap) {
		total += channelHasNewMessageMap[key];
	}

	return total;
});

export const channelHasNewMessageMap = reactive({}) as Record<string, number>;
export const activeChannel = ref<ChatChannel>();
export const activeAttendeeId = ref("default");
export const chatOpened = ref(false);
export const chatDirty = ref(false);
export const chatAttachmentsEnabled = ref(!useHelpers().isMobileBrowser() && !useHelpers().isIpad());
// Fields
export let chatEnabled = computed(() => false);
export let localAttendeeId = "";
export let possibleSendToAttendees = ref([] as Attendee[]);

const chatAttendees: Attendee[] = [];
let role = ref("VIEWER" as AttendeeRole);
let _meeting: Meeting;

/**
 * Setup method
 * @param meeting setup meeting object
 */
export async function setupChat(meeting: Meeting) {
	_meeting = meeting;
	localAttendeeId = _meeting.localAttendee.id;

	chatEnabled = computed(() => {
		return _meeting.isChatEnabled;
	});

	role = computed(() => _meeting.localAttendee.role);

	possibleSendToAttendees = computed(() => {
		const filtered = _meeting.attendees.filter(
			(a) => a.id !== localAttendeeId && a.type != "SIPINBOUND" && a.displayName != "LiveSwitch Bot"
		);
		let attendees: Attendee[] = [];

		filtered.forEach((attendee) => {
			attendees.push(attendee);
		});

		// if an attendee left the meeting but was part of a private message their record will be in a chat channel
		// iterate through the channels and add any attendees to the dropdown list so we can still see those messages
		const chatChannels = _meeting.chat?.channels.filter((c) => !c.isDefault);
		chatChannels?.forEach((channel) => {
			const members = channel.members.filter((m) => m.attendee.id !== localAttendeeId);
			members.forEach((member) => {
				if (attendees.findIndex((a) => a.id == member.attendee.id) === -1) {
					attendees.push(member.attendee);
				}
			});
		});

		attendees = attendees.sort((a, b) => a.displayName.localeCompare(b.displayName));
		chatDirty.value;
		return attendees;
	});

	disableChat = () => {
		const task = _meeting.disableChat();
		chatDirty.value = false;
		return task;
	};

	enableChat = () => {
		const task = _meeting.enableChat();
		return task;
	};

	tryFindChannelForAttendee = (attendeeId: string) => {
		if (!_meeting?.chat) {
			return undefined;
		}

		if (attendeeId.toLowerCase() === "default") {
			return _meeting.chat?.defaultChannel;
		}

		return _meeting.chat?.channels.find(
			(c) => c.name?.toLowerCase() !== "default" && c.members.some((m) => m.attendee.id === attendeeId)
		);
	};

	watch(activeAttendeeId, async (targetAttendeeId) => {
		const channel = await getChannel(targetAttendeeId);
		activeChannel.value = channel;
	});

	watch(chatEnabled, (isChatEnabled, wasChatEnabled) => {
		if (isChatEnabled && !wasChatEnabled) {
			attachMessageEvents();
			activeChannel.value = _meeting.chat?.defaultChannel;
			activeAttendeeId.value = "default";
		}
	});

	getAttendeeDisplayName = (attendee: Attendee | undefined) => {
		let name = attendee?.displayName;

		if (!name) {
			const chatAttendee = chatAttendees.find((a) => a.id === attendee?.id);
			name = chatAttendee?.displayName ?? "Guest";
		}

		return name;
	};

	watch(
		() => _meeting.isChatEnabled,
		(newValue, oldValue) => {
			if (!newValue && oldValue) {
				if (_meeting.state == "joined") {
					useEventBus().navAlert("Chat has been DISABLED by the host/moderator");
				}
			} else {
				if (_meeting.state == "joined") {
					useEventBus().navAlert("Chat has been ENABLED by the host/moderator");
				}
			}
		}
	);

	if (_meeting.chat) {
		await initializeDefaultChannel();
	} else {
		console.warn("Meeting chat is not initialized yet. Wait for chat to initialize to set public channel");

		watch(_meeting.chat, async (newChat, oldChat) => {
			if (!oldChat && newChat && !activeChannel.value) {
				await initializeDefaultChannel();
				console.warn("Delayed chat initialization. Public chat channel set");
			}
		});
	}
}

async function getChannel(targetAttendeeId: string) {
	let channel = tryFindChannelForAttendee(targetAttendeeId);

	if (!channel && _meeting?.chat) {
		// Create new channel when attendeeId Changed (from <select>)

		const otherMember = _meeting.attendees.find((x) => x.id === targetAttendeeId);

		channel = await _meeting.chat.createChannel({
			name: `chat-${localAttendeeId}-${otherMember?.id}`,
			type: "PRIVATE",
		});

		await channel.addMember({
			attendeeId: localAttendeeId,
			role: "OWNER",
		});

		if (otherMember) {
			await channel.addMember({
				attendeeId: otherMember.id,
				role: "MEMBER",
			});
		}
	}

	return channel;
}

async function initializeDefaultChannel() {
	activeChannel.value = _meeting.chat?.defaultChannel;
	_meeting.chat?.channels.forEach((c) => {
		channelHasNewMessageMap[c.id ?? "default"] = c.messages.filter(
			(x) => x.text?.indexOf("so_cmd") == -1 && x.attendee.id != _meeting.localAttendee.id
		).length;
	});
	attachMessageEvents();
	setTimeout(loadMoreDefaultChannelMessages, 1);
}

async function loadMoreDefaultChannelMessages() {
	await _meeting?.chat?.defaultChannel?.messages?.loadMore();
	if (_meeting?.chat?.defaultChannel?.messages?.hasMore) {
		console.info("fetching previous chat messages");
		await setTimeout(loadMoreDefaultChannelMessages, 1);
	} else {
		console.info("loaded all chat messages");
	}
}

function attachMessageEvents() {
	_meeting.chat?.defaultChannel.messages.added.bind(async (e) => {
		onNewMessage(e.element);
	});

	_meeting.chat?.channels.added.bind(async (e) => {
		e.element.messages.added.bind(async (evt) => {
			onNewMessage(evt.element);
		});
	});
}

function onNewMessage(message: Message) {
	const channelId = (message.channel.id ?? "default").toLowerCase();

	if (!channelHasNewMessageMap[channelId]) {
		channelHasNewMessageMap[channelId] = 0;
	}

	newMessageCallback(channelId);
	newPMMessageCallback(channelId, message);
	let diffSeconds = 30;
	const now = new Date().getTime();
	let timestamp: Date | string = message.timestamp;

	try {
		if (typeof timestamp === "string") {
			timestamp = new Date(timestamp);
		} else if (!timestamp) {
			timestamp = new Date();
		}

		diffSeconds = (now - timestamp.getTime()) / 1000;
	} catch (err) {
		console.warn(`Error parsing chat message timestamp: Timestamp=${timestamp}`);
	}

	if (message.text && message.text.indexOf("so_cmd") == 0) {
		processCommand(message.text);
	} else {
		channelHasNewMessageMap[channelId] += 1;
	}

	// TODO: MK - Try to figure out why we needed this

	// if (diffSeconds < 2) {
	// 	if (message.text && message.text.indexOf("so_cmd") == 0) {
	// 		processCommand(message.text);
	// 	} else {
	// 		channelHasNewMessageMap[channelId] += 1;
	// 	}
	// }
}

function processCommand(command: string) {
	command = command.replace("so_cmd", "");
	const model = JSON.parse(command) as EmojiReaction;

	if (model.command == "reaction") {
		useEventBus().emitEvent("emoji-reaction", model);
	}
}

export function setupNewMessageCallback(callback: (channelId: string) => void) {
	newMessageCallback = callback;
}

export function setupNewPMMessageCallback(callback: (channelId: string, message: ChatMessage) => void) {
	newPMMessageCallback = callback;
}

let disableChat = () => {
	return Promise.resolve();
};

let enableChat = () => {
	return Promise.resolve();
};

let tryFindChannelForAttendee = (attendeeId: string) => {
	if (!_meeting?.chat) {
		return undefined;
	}

	return _meeting?.chat?.channels.find((channel) =>
		channel.members.some((member) => member.attendee.id === attendeeId)
	);
};

let newMessageCallback = (channelId: string) => {};
let newPMMessageCallback = (channelId: string, message: ChatMessage) => {};
export let getAttendeeDisplayName = (attendee: Attendee | undefined) => "";

// Functions
export function hasNewMessageForAttendee(attendeeId: string) {
	const channel = tryFindChannelForAttendee(attendeeId);
	if (channel) {
		return channelHasNewMessageMap[channel.id ?? "default"] ?? 0;
	}
	return false;
}

export function resetNewChatMessages() {
	channelHasNewMessageMap[activeChatChannelId.value.toLowerCase()] = 0;
}

export async function toggleChatEnableAsync() {
	if (chatEnabled.effect.fn()) {
		await disableChat();
	} else {
		await enableChat();
		setTimeout(loadMoreDefaultChannelMessages, 1);
	}
}

export async function sendMessageAsync(message: string, files?: File[], targetAttendeeId?: string) {
	if ((!message || message.trim() === "") && (!files || files.length == 0)) {
		return;
	}

	message = DOMPurify.default.sanitize(message, { USE_PROFILES: { html: false } });

	// TODO: Workaround for slow chat init

	if (!activeChannel.value && _meeting.chat?.defaultChannel) {
		activeChannel.value = _meeting.chat.defaultChannel;
	}

	if (targetAttendeeId) {
		const channel = await getChannel(targetAttendeeId);

		if (channel) {
			activeChannel.value = channel;
		}
	}

	if (activeChannel.value) {
		const tasks: Promise<Message>[] = [];

		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileTask = activeChannel.value.send(file, {
					fileName: file.name,
					mimeType: file.type,
					priority: "HIGHEST",
				} as MessageSendFileOptions);

				tasks.push(fileTask);
			}
		}

		if (message && message.length > 0) {
			tasks.push(activeChannel.value.send(message));
		}

		await Promise.all(tasks);
	}
}

export async function loadPreviousMessagesForActiveChannelAsync() {
	activeChannel.value?.messages.loadMore(10);
}

export function attendeeJoined(attendee: Attendee) {
	const existing = chatAttendees.find((x) => x.id == attendee.id);

	if (existing) {
		const index = chatAttendees.indexOf(existing);
		chatAttendees.splice(index, 1);
	}

	chatAttendees.push(attendee);
}

function clearNewMessages() {
	for (const key in channelHasNewMessageMap) {
		channelHasNewMessageMap[key] = 0;
	}
}
