﻿<template>
	<prejoin-check :meeting-handler="props.meetingHandler"></prejoin-check>
	<div class="container lobby-container" :class="{ 'mt-4': !useHelpers().isMobileBrowser() }">
		<div class="row flex-md-nowrap lobby-row">
			<div class="col-md-6 col-sm-6 preview-container">
				<div class="h4" :class="{ 'no-display': useHelpers().isMobileBrowser() }">Preview</div>
				<div class="video-preview">
					<LocalVideo
						v-if="useCanvas()"
						ref="localVideo"
						:user-media="userMedia"
						:blurred="false"
						:meeting-handler="props.meetingHandler"
						@videoReady="localVideoReady"></LocalVideo>
					<video v-else 
						muted 
						playsinline 
						autoplay
						:srcObject.prop.camel="userMedia?.stream"
						:class="{ 'back-facing': userMedia?.videoFacingMode === 'environment' }">
					</video>
					<div class="video-controls container row justify-content-center">
						<div class="col-auto">
							<button class="btn btn-link btn-white btn-sm" @click="toggleAudio()" v-show="!audioMuted">
								<i class="fas fa-microphone"></i>
							</button>
							<button
								class="btn btn-link btn-white btn-sm muted"
								@click="toggleAudio()"
								v-show="audioMuted">
								<i class="fas fa-microphone-slash"></i>
							</button>
						</div>
						<div class="col-auto">
							<button class="btn btn-link btn-white btn-sm" @click="toggleVideo()" v-show="!videoMuted">
								<i class="fas fa-video"></i>
							</button>
							<button
								class="btn btn-link btn-white btn-sm muted"
								@click="toggleVideo()"
								v-show="videoMuted">
								<i class="fas fa-video-slash"></i>
							</button>
						</div>
						<div
							class="col-auto"
							:title="videoBlurred ? 'Remove Background Blur' : 'Blur Background'"
							v-if="blurEnabled">
							<button
								class="btn btn-link btn-white btn-sm"
								@click="setVideoBlurEnabled(true)"
								v-show="props.meetingHandler.backgroundMode !== 'blur'">
								<div>
									<i class="icon-user-background-blur" />
								</div>
							</button>
							<button
								class="btn btn-link btn-white btn-sm blurred"
								@click="setVideoBlurEnabled(false)"
								v-show="props.meetingHandler.backgroundMode == 'blur'">
								<div>
									<i class="icon-user-background-default" />
								</div>
							</button>
						</div>
						<div
							class="col-auto"
							:title="props.meetingHandler.backgroundMode == 'virtual' ? 'Remove Virtual Background' : 'Virtual Background'"
							v-if="blurEnabled">
							<!-- no virtual backgrounds on mobile -->
							<button
								class="btn btn-link btn-white btn-sm"
								@click="setVirtualBackgroundEnabled(true)"
								v-if="!useHelpers().isMobileBrowser()"
								v-show="props.meetingHandler.backgroundMode !== 'virtual'">
								<div>
									<i class="fa-regular fa-image" />
								</div>
							</button>
							<button
								class="btn btn-link btn-white btn-sm blurred"
								@click="setVirtualBackgroundEnabled(false)"
								v-show="props.meetingHandler.backgroundMode == 'virtual'">
								<div>
									<i class="icon-user-background-default" />
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- 
				the min-height here is so we ensure we have a little room to scroll the ui below the join/cancel buttons,
				even if the rest of the page is locked down to un-scrollable
			-->
			<div class="col-md-6 col-sm-6 form-group" style="min-width: 30vw;min-height: 530px;">
				<div class="h4 setup">Set Up</div>
				<div class="row">
					<div :class="meetingIdStyle">
						<div class="h6 mt-3">Meeting ID</div>
						<input class="form-control" type="text" disabled style="color: darkgray" :value="channelKey" />
						<div class="alert alert-danger mb-1" role="alert" v-if="meetingBlankError">
							A meeting ID is required. Please check your meeting link and try again. <a v-if="false" href="#" style="color: black">Go to my personal room.</a>
						</div>
						<div class="alert alert-danger mb-1" role="alert" v-if="meetingIdError1">
							We're sorry. The room information could not be found. Please refresh and try again.
						</div>
						<div class="alert alert-danger mb-1" role="alert" v-if="meetingIdError2">
							We're sorry. The room could not be found. Please refresh and try again.
						</div>
						<div class="alert alert-danger mb-1" role="alert" v-if="meetingIdError3">
							We're sorry. The meeting could not be found. Details: {{ meetingIdError3Message }}
						</div>
					</div>
					<div class="col-6" v-if="passcodeRequired">
						<div class="h6 mt-3">Passcode</div>
						<input class="form-control" type="text" v-model="passcode" />
						<div class="alert alert-danger mb-1" role="alert" v-if="passcodeError">
							The passcode is required
						</div>
					</div>
				</div>
				<div class="h6 mt-2" v-if="isMediaReady">Select Camera</div>
				<div class="with-icon" v-if="isMediaReady">
					<div class="select-icon">
						<i class="fas fa-video text-dark"></i>
					</div>
					<select class="form-select" v-model="videoInputSelectedId" @change="saveSelectedDevices">
						<option
							v-for="d in meetingHandler.videoInputs"
							v-text="d.label"
							:value="d.id"
							:key="d.id"></option>
					</select>
				</div>

				<div class="h6 mt-2" v-if="isMediaReady">Select Microphone</div>
				<div class="with-icon" v-if="isMediaReady" style="">
					<div class="select-icon" v-if="badMicrophone">
						<i 							
							class="fas fa-microphone-slash mic-indicator-icon" 
							style="height:16px;color:red;">
						</i>
					</div>
					<div class="select-icon" v-else>
						<i 
							class="fas fa-microphone mic-indicator-icon" 
							style="height:16px;color:#000;">
						</i>
					</div>
					<select class="form-select with-icon" v-model="audioInputSelectedId" @change="saveSelectedDevices">
						<option
							v-for="d in meetingHandler.audioInputs"
							v-text="d.label"
							:value="d.id"
							:key="d.id"></option>
					</select>
					<div 
						style="position: relative;width:100%;height:4px;background-color: white;overflow:hidden;margin-top:4px;border-radius:6px;">
						<div 
							:style="{ 
								height: '100%',
								width: audioIndicatorWidth,
								overflow: 'hidden',
								'background-color': 'limegreen'
							}">
						</div>
					</div>
				</div>
				<div v-if="isMediaReady">
					<div class="h6 mt-2">Select Speaker</div>
					<div class="with-icon with-icon-right-outside">
						<div class="select-icon">
							<i class="fas fa-volume-up text-dark"></i>
						</div>
						<select class="form-select with-icon" v-if="isMediaReady" v-model="audioOutputSelectedId">
							<option
								v-for="d in meetingHandler.audioOutputs"
								v-text="d.label"
								:value="d.id"
								:key="d.id"></option>
						</select>
						<button 
							@click.stop.prevent="toggleTestAudio()"  
							style="right:-40px;position:absolute;height:36px;top:0px;bottom:0"
							class="btn btn-link btn-white btn-sm"
							:class="{
								'playing': playing
							}">
							<div>
								<i class="fas fa-play" />
								<i class="fas fa-pause" />
							</div>
						</button>
					</div>
				</div>

				<div class="h6 mt-2">Display Name</div>
				<input
					id="display-name"
					class="form-control"
					type="text"
					v-model="userName"
					:disabled="userNameDisabled"
					maxlength="50" />
				<span class="terms">
					By using this service, I agree to the
					<a href="https://www.liveswitch.com/legal-aup" target="_blank">Terms of Use</a>
					and the
					<a href="https://www.liveswitch.com/legal-privacy-policy" target="_blank">Privacy Policy</a>.
				</span>
				<div class="row mt-4 button-container" :class="{ 'ios-bottom-margin': useHelpers().isMobileIos() }">
					<div class="col-md-6 col-sm-12 mt-2">
						<button
							id="btn-join"
							type="button"
							class="btn btn-primary btn-join"
							:class="joinedLobby ? 'waiting' : ''"
							@click="joinCall"
							:disabled="joinDisabled || joinedLobby">
							{{ joinButtonText }}
						</button>
					</div>
					<div class="col-md-6 col-sm-12 mt-2">
						<button id="btn-cancel" type="button" class="btn btn-light btn-cancel" @click="cancel">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row flex-md-nowrap meeting-name" v-if="channelName != '' && !useHelpers().isMobileBrowser()">
			<div class="col-sm-12">Meeting Name: {{ channelName }}</div>
		</div>
		<PermissionsHelper v-model:show="showPermissionsHelper"></PermissionsHelper>

	</div>
</template>

<script lang="ts" setup>
	import { computed, inject, onMounted, onUnmounted, reactive, ref, watch } from "vue";
	import VideoBlurHelper from "@/classes/VideoBlurHelper";
	import type { PropType } from "vue";
	import type { JoinConfiguration } from "@/classes/EventContracts";
	import useEventBus from "../composables/useEventBus";
	import { useRoute } from "vue-router";
	import type { ChannelDetails } from "@/classes/ChannelService";
	import { authenticationServiceKey, channelServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import type { IChannelService } from "../classes/ChannelService";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import {
		ApiClient,
		BadRequestError,
		HttpClient,
		Identity,
		Log,
		MediaDeviceManager,
		models,
		version,
	} from "@liveswitch/sdk";
	import useHelpers from "@/composables/useHelpers";
	import useLocalStorage, { blurEnabled } from "@/composables/useLocalStorage";
	import LocalVideo from "@/components/LocalVideo.vue";
	import PrejoinCheck from "@/components/PrejoinCheck.vue";
	import type {
		IAuthenticationService,
		AuthenticationValidationResponse,
		SignInRequest,
	} from "@/classes/AuthenticationService";
	import { SeverityLevel, ApplicationInsights, Exception } from "@microsoft/applicationinsights-web";
	import type LobbyHandler from "@/classes/LobbyHandler";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import LiveSwitchUserMedia from "@/classes/LiveSwitchUserMedia";
	import PermissionsHelper from "@/components/modals/PermissionsHelper.vue";

	import audioUrl from '@/assets/audio/sample.mp3'
	import CanvasUserMedia from "@/classes/CanvasUserMedia";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;

	// Defines
	const emit = defineEmits<{
		(event: "join", config: JoinConfiguration): void;
		(event: "exitLobby"): void;
	}>();

	const props = defineProps({
		joinProgress: { type: Boolean, required: true },
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	onUnmounted(() => {
		if (useHelpers().isSafari()) {
			console.debug("Not stopping video tracks for Safari");
		} else {
			//stopTracks();
		}
	});

	function stopTracks() {
		if (mediaStream.value) {
			try {
				mediaStream.value.getTracks().forEach(function (track) {
					track.stop();
				});

				mediaStream.value = undefined;
			} catch (ex: any) {
				console.error("Error stopping media stream tracks", ex);
			}
		}
	}
	
	const audioInputDevice = ref<MediaDevice>()
	
	const route = useRoute();
	const channelService = inject(channelServiceKey) as IChannelService;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	// Constants
	const videoWidthHD = 1280;
	const videoMaxWidthHD = 1920;
	const videoHeightHD = 720;
	const videoMaxHeightHD = 1080;
	const videoWidth = 640;
	const videoHeight = 480;
	const cancelled = "CANCELED";

	// References]
	const channelKey = ref("");
	const channelName = ref("");
	const passcode = ref("");
	const passcodeRequired = ref(false);
	const validChannel = ref(false);
	const subject = ref("");
	const isMediaReady = ref(true);
	const areDevicesBlocked = ref(false);
	const currentMediaName = ref("");
	const audioMuted = ref(false);
	const videoMuted = ref(false);
	const videoBlurred = ref(false);
	const virtualBackgroundEnabled = ref(false);
	const showVideoBlur = ref(true);
	const userName = ref("");
	const userNameDisabled = ref(true);
	const freeAccount = ref(true);
	const meetingIdError1 = ref(false);
	const meetingIdError2 = ref(false);
	const meetingIdError3 = ref(false);
	const meetingIdError3Message = ref("");
	const meetingBlankError = ref(false);
	const passcodeError = ref(false);
	const callChannel = ref<ChannelDetails>();
	const tokenUrl = ref("");
	const apiKey = ref("");
	const ls2Token = ref("");
	const botSocketUrl = ref("");
	const features = ref<object>();
	const streamInitialized = ref(false);
	const noMic = ref(false)
	const streamInitializedTimeout = useHelpers().isSafari() ? 1000 : 1;

	const userMedia = ref<LiveSwitchUserMedia>();
	let meetingRoom = ref<models.RoomInfo>();
	let identity: Identity;
	let channelValidationTask: Promise<void>;
	let lobbyValidationTask: Promise<void>;
	let channelValidationComplete = false;
	const isLobbyEnabled = ref(false);
	const joinedLobby = ref(false);
	const botEnabled = ref(false);
	const localVideo = ref();
	const permissionsMessage =
		"LiveSwitch Video requires access to your camera and microphone to join a meeting. Please enable your <a href='https://help.liveswitch.com/article/51-resolve-multiple-webcam-and-mic-permission-notifications' target='_blank'>camera and microphone permissions</a> and reload the page.";
	const showPermissionsHelper = ref(false);
	const meetingIdStyle = computed(() => {
		return {
			"col-6": passcodeRequired.value,
			"col-12": !passcodeRequired.value,
		};
	});

	const audioInputSelectedId = ref<string>();
	const videoInputSelectedId = ref<string>();
	const audioOutputSelectedId = ref<string>();
	const eventBus = useEventBus();
	const backFacing = ref(false);
	const mediaStream = ref<MediaStream>();
	const facingMode = "user";

	//(globalThis as any).__deviceManager = deviceManager
	const useCanvas = () => {
		return userMedia.value && blurEnabled.value
	};
	
	let playing = ref(false)

	/** Functions */
	MediaDeviceManager.shared.audioInputsUpdated.bind(async (args: DevicesEvent) => {
		console.log('audio inputs have changed', args)
		if(args.updated?.length){
			for(let i = 0; i < args.updated.length; i++){
				console.log(args.updated[i].id, audioInputSelectedId.value)
				if(args.updated[i].id == audioInputSelectedId.value){
					// in this case, we also want to update the audio monitor
					// it's a bit weird, because it's the same stream object, but the underlying track is changing
					// so we need to reconnect the audio monitor. the settimeout is to give the stream time to initialize
					// despite the fact that the track is already live; it's odd, but the only way to get consistency
					console.log('reconnecting preview')
					let attempter = function(){
						console.log('checking track...')
						let audioTrack = userMedia.value?.stream.getTracks().find(x=>x.kind == 'audio')
						if(audioTrack && audioTrack.readyState == 'live'){
							console.log('has audio, connecting')
							showAudioPreview((userMedia.value as LiveSwitchUserMedia).stream)
						}else{
							console.log('no audio, retrying')
							window.setTimeout(attempter,500)
						}
					}
					window.setTimeout(attempter, 500)

				}
			}
		}
	})

	const toggleTestAudio = () => {
		if(playing.value){
			pauseTestAudio()
		}else{
			playTestAudio()
		}
	}

	let _audio : HTMLAudioElement


	const createOrGetAudio = () => {
		if(!_audio){
			console.log('creating')
			_audio = new Audio(audioUrl)
		}
		return _audio
	}

	const pauseTestAudio = () => {
		let audio = createOrGetAudio()
		audio.pause()
		playing.value = false
	}
	const playTestAudio = () =>{
		let audio = createOrGetAudio()
		audio.currentTime = 0
		audio.play()
		audio.onended = () =>{
			pauseTestAudio()
		}
		playing.value = true
	}

	function isSafari() {
		return navigator.userAgent.includes("Safari");
	}

	function saveSelectedDevices() {
		if(audioInputSelectedId.value){
			localStorage.setItem("audioInput", audioInputSelectedId.value);
		}
		if(videoInputSelectedId.value){
			localStorage.setItem("videoInput", videoInputSelectedId.value);
		}
		if(audioOutputSelectedId.value){
			localStorage.setItem("audioOutput", audioOutputSelectedId.value);
		}
		console.log('saving preferences')
	}

	const videoStreamHeight = computed(() => {
		return userMedia.value?.stream.getVideoTracks()[0].getSettings().height;
	});

	const videoStreamWidth = computed(() => {
		return userMedia.value?.stream.getVideoTracks()[0].getSettings().width;
	});

	const joinDisabled = computed(() => {
		let disabled = !channelKey.value || !userName.value;

		if (!isMediaReady.value) {
			disabled = true;
		}

		if(!props.meetingHandler.userMedia){
			disabled = true
		}

		if (userName.value?.trim().length == 0) {
			disabled = true;
		}

		if(noMic.value){
			disabled = true
		}

		return disabled;
	});

	const joinButtonText = computed(() => {
		if (useLocalStorage().getChannel()?.MeetingOwner) {
			return "Join";
		}
		return !joinedLobby.value ? "Join" : "Waiting for Host...";
	});

	const getRouteParams = () : Record<string, string> => {
		const keys = Object.keys(route.query);
		const params: Record<string, string> = {};
		keys.forEach((key) => {
			const value = route.query[key];
			params[key.toLowerCase()] = value as string;
		});
		return params;
	}

	const getChannelByKey = async (key : string) : Promise<ChannelDetails> => {
		return await channelService?.getChannelAsync(key);
	}

	function createUserMedia() {
		let dt = props.meetingHandler.detectRtc
		userMedia.value = new LiveSwitchUserMedia(
				dt.hasMicrophone,
				dt.hasWebcam
		);
	}

	async function startUserMedia() {
		await userMedia.value?.start()
		showAudioPreview((userMedia.value as LiveSwitchUserMedia).stream)
	}

	async function applyUserPreferences() {
		//localStorage.removeItem("hasHdCamera");

		// Try restore from previous saved state
		if (!useHelpers().isSafari()) {
			// microphone
			let userPreferredAudioInputId =  props.meetingHandler.audioInputs.find(
				(d) => d.id === localStorage.getItem("audioInput")
			)?.id

			if(userPreferredAudioInputId){
				audioInputSelectedId.value = userPreferredAudioInputId;
				console.log('user pref for audio input, new value', audioInputSelectedId.value)
			}

			// webcam
			let userPreferredVideoInputId = props.meetingHandler.videoInputs.find(
				(d) => d.id === localStorage.getItem("videoInput")
			)?.id;

			console.log('found preferred id', userPreferredVideoInputId)
			if(userPreferredVideoInputId){
				videoInputSelectedId.value = userPreferredVideoInputId
				console.log('user pref for audio input, new value', videoInputSelectedId.value)
			}

			// speakers
			let userPreferredAudioOutputId =  props.meetingHandler.audioOutputs.find(
				(d) => d.id === localStorage.getItem("audioOutput")
			)?.id;

			if(userPreferredAudioOutputId){
				audioOutputSelectedId.value = userPreferredAudioOutputId
				console.log('user pref for audio output, new value', audioOutputSelectedId.value)
			}
		}

		//await startUserMedia();
		//await getStream();
		//isMediaReady.value = true;
	}

	/*async function getStream() {
		streamInitialized.value = false;

		const videoLabel = deviceManager.videoInputs
			.find((v) => v.id === videoInputSelectedId.value)
			?.label.toLowerCase();
		if (videoLabel?.includes("back")) {
			backFacing.value = true;
		} else {
			backFacing.value = false;
		}

		setTimeout(() => {
			streamInitialized.value = true;
		}, streamInitializedTimeout);

		mediaStream.value = userMedia.value?.stream;
		saveSelectedDevices();

		// Set camera drop down to match device used by preview stream
		if (userMedia.value?.stream && userMedia.value?.stream?.getVideoTracks()?.length > 0) {
			const videoDeviceId = userMedia.value.stream.getVideoTracks()[0].getSettings().deviceId;
			const videoDevice = deviceManager.videoInputs.find((x) => x.id === videoDeviceId);

			if (videoDevice != null) {
				videoInputSelectedId.value = videoDevice.id;
				currentMediaName.value = videoDevice.label;
			}
		}

		// Set mic drop down to match device used by preview stream
		if (userMedia.value && userMedia.value?.stream?.getAudioTracks()?.length > 0) {
			
			const audioTrack : MediaStreamTrack = ((userMedia.value as LiveSwitchUserMedia).stream as MediaStream).getAudioTracks()[0]
			const audioDeviceId = audioTrack.getSettings().deviceId;
			audioInputDevice.value = deviceManager.audioInputs.find((x) => x.id === audioDeviceId);

			if (audioInputDevice?.value) {
				audioInputSelectedId.value = audioInputDevice.value.id;
			}
		}

		const videoElement = document.querySelector("video");

		if (videoElement) {
			if (isSafari()) {
				// Autoplay does not work in Safari when the mediastream contains audio
				// NOTE: not quite accurate. Autoplay does work in Safari, but only if the video is muted.
				// Reference: https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari#3030251
				// but also shows the "pause" icon in low power mode in that case
				 
				videoElement.removeAttribute("autoplay");
				videoElement.setAttribute("muted", "true");
				videoElement.onloadedmetadata = () => {
					videoElement.play();
				};
			}

			try {
				videoElement.srcObject = userMedia.value?.stream;
			} catch (error) {
				videoElement.src = URL.createObjectURL(userMedia.value?.stream as any);
			}
		}

		if (userMedia.value?.stream && videoMuted.value) {
			const tracks = userMedia.value.stream.getTracks();
			tracks[0].enabled = false;
		}

		isMediaVideoReady.value = true;
	}*/


	let _audioPreviewContext : AudioContext
	let _mediaStreamAudioSourceNode : MediaStreamAudioSourceNode
	let _analyserNode: AnalyserNode
	let _audioInputLevel = ref(0)

	const getAudioContext = () : AudioContext | undefined => {
		return new AudioContext()
		if(!_audioPreviewContext){
			try{
			_audioPreviewContext = new AudioContext();
			}catch(e){
				console.error(e)
			}
		}
		return _audioPreviewContext
	}

	const audioIndicatorWidth = computed(() => {
		return (_audioInputLevel.value * 100) + '%'
	})

	const badMicrophone = computed(() => {
		return _foundAudio.value == false && _foundAudioTimeExpired.value == true
	})

	const _foundAudio = ref(false)
	const _foundAudioTimeExpired = ref(false)
	let _foundAudioTimeeout = 0
	const showAudioPreview = (stream: MediaStream) => {
		if(!stream){
			console.warn('Unable to show audio preview, no stream was found.')
			return
		}
		console.log('connecting preview')

		if(_mediaStreamAudioSourceNode){
			_mediaStreamAudioSourceNode.disconnect()
		}

		let audioContext = getAudioContext()
		if(!audioContext){
			console.log('failed to get audio context')
			return
		}
		_mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
		_analyserNode = audioContext.createAnalyser();
		_mediaStreamAudioSourceNode.connect(_analyserNode);

		const pcmData = new Float32Array(_analyserNode.fftSize);

		_foundAudioTimeExpired.value = false
		_foundAudio.value = false
		window.clearTimeout(_foundAudioTimeeout)
		_foundAudioTimeeout = window.setTimeout(() => {
			_foundAudioTimeExpired.value = true
		}, 3000)
		
		const onFrame = () => {
			_analyserNode.getFloatTimeDomainData(pcmData);
			let sumSquares = 0.0;
			for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }
			let level = Math.sqrt(sumSquares / pcmData.length)
			if(level > 0.002 || _audioInputLevel.value > 0.1){
				// typically somewhere from 0-4 after multiplying
				let tempLevel = (level) * 10
				// now we normalize from 0-1
				if(tempLevel > _audioInputLevel.value){
					_audioInputLevel.value = tempLevel
				}else{
					_audioInputLevel.value = _audioInputLevel.value * 0.9
				}
				if(!_foundAudio.value){
					_foundAudio.value = true
					console.log('sound detected', tempLevel)
				}
			}else{
				_audioInputLevel.value = 0
				//console.log('no sound', level)
			}
			window.requestAnimationFrame(onFrame);
		};
		window.requestAnimationFrame(onFrame);
	}

	async function localVideoReady() {
		//userMedia.value = 
		const canvasUserMedia = new CanvasUserMedia(true, true, localVideo.value.canvasStream, userMedia.value as LiveSwitchUserMedia)
		await canvasUserMedia.start();
		props.meetingHandler.userMedia = canvasUserMedia;
		console.log('local canvas is ready, allowing media ready for join')
		isMediaReady.value = true;
	}

	function handleMediaStreamError(err: Error, retryCount: number) {
		errTitle.value = "Error";
		errMessage.value = err.message;
		errShouldShow.value = true
		let retry = false;

		appInsights.trackException(
			{
				exception: err,
				id: "MediaStreamError",
				severityLevel: SeverityLevel.Critical,
			},
			useHelpers().getLoggingProperties(err.name, err.message)
		);

		console.log(err);
		try{
		localStorage.removeItem("videoInput");
		localStorage.removeItem("audioInput");
		localStorage.removeItem("audioOutput");
		}catch(e){console.error(e)}

		let displayPermissionsModal = false;

	    if (err.name == "TrackStartError" || err.name == "AbortError" || err.name == "DevicesNotFoundError" || err.name == "NotAllowedError" || err.name == "PermissionDeniedError" || err.name == "NotFoundError" || err.name == "NotReadableError") {
			audioMuted.value = true;
			videoMuted.value = true;
			areDevicesBlocked.value = true;
			streamInitialized.value = true;

			errMessage.value = permissionsMessage;
			displayPermissionsModal = true;
		} 
		else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {

			retry = true;
			errTitle.value = "Device Error";
			errMessage.value =
				"Your camera or microphone are already in use. Close any applications that may be using your camera or microphone and try again.";
		}


		if (retryCount > 1) {
			retry = false;
		}

		if (!retry) {
			if (displayPermissionsModal) {
				showPermissionsHelper.value = true;
			}
		}

		areDevicesBlocked.value = true;
		return retry;
	}

	const errShouldShow = ref(false)
	const errTitle = ref('')
	const errMessage = ref('')
	const errMessageComputed = computed(() =>{
		return props.meetingHandler.getErrorMessageWithDialIn(errMessage.value)
	})

	watch(errMessageComputed, (newVal, oldVal) => {
		// if the error message changes, update it; this lets us use computed props in the error
		// message, for example phone numbers
		// we also explicitly set the title to blank, Rob wants to kill em all.
		if(!errShouldShow.value){
			return
		}
		if(Swal.isVisible()){
			Swal.update({
				//title: errTitle.value,
				title: '',
				html: errMessageComputed.value,
				confirmButtonText: "OK",
			});
		}else{
			Swal.fire({
				//title: errTitle.value,
				title: '',
				html: errMessageComputed.value,
				confirmButtonText: "OK",
			});
		}
	})

	watch(userName, (newName) => {
		if (newName.length) {
			localStorage.setItem("Username", newName);
		}
	});

	async function applyAndWatchSelectedAudioAndVideoInput(){
		watch(audioInputSelectedId, async () => {
			console.debug("Audio Input Device changed. Getting updated media stream");
			await userMedia.value?.setAudioDevice(audioInputSelectedId.value);
			//saveSelectedDevices();

			showAudioPreview((userMedia.value as LiveSwitchUserMedia).stream)
		});
		watch(videoInputSelectedId, async () => {
			console.debug("Video Input Device changed. Getting updated media stream" + ' ' + videoInputSelectedId.value);
			await userMedia.value?.setVideoDevice(videoInputSelectedId.value);
			//saveSelectedDevices();
		});
		if(userMedia.value?.audioDeviceId != audioInputSelectedId.value && audioInputSelectedId.value){
			await userMedia.value?.setAudioDevice(audioInputSelectedId.value);
		}
		if(userMedia.value?.videoDeviceId != videoInputSelectedId.value && videoInputSelectedId.value){
			await userMedia.value?.setVideoDevice(videoInputSelectedId.value);
		}
	}

	// in theory, this function should do nothing, but it's here to ensure that the user media is set up correctly and matches the ui
	function doubleCheckSelectionsAndPreview(){
		// Set camera drop down to match device used by preview stream
		if (userMedia.value?.stream && userMedia.value?.stream?.getVideoTracks()?.length > 0) {
			// pull the actual value from the user media
			const videoDeviceId = userMedia.value.stream.getVideoTracks()[0].getSettings().deviceId;
			// find the video input that matches the id
			const videoDevice = props.meetingHandler.videoInputs.find((x) => x.id === videoDeviceId);

			if (videoDevice != null && videoDeviceId != videoDevice.id) {
				console.log('mismatch found- syncing video input')
				videoInputSelectedId.value = videoDevice.id;
			}
		}

		// Set mic drop down to match device used by preview stream
		if (userMedia.value && userMedia.value?.stream?.getAudioTracks()?.length > 0) {
			
			const audioTrack : MediaStreamTrack = ((userMedia.value as LiveSwitchUserMedia).stream as MediaStream).getAudioTracks()[0]
			const audioDeviceId = audioTrack.getSettings().deviceId;

			const audioDevice = props.meetingHandler.audioInputs.find((x) => x.id === audioDeviceId);

			if (audioDevice != null && audioDeviceId != audioDevice.id) {
				console.log('mismatch found- syncing audio input', audioInputSelectedId.value, audioDeviceId)
				audioInputSelectedId.value = audioDevice.id
			}
		}
	}

	function applyDefaultDevices() {
		// Assign defaults for unrestored
		if (!audioInputSelectedId.value) {
			audioInputSelectedId.value = props.meetingHandler.audioInputs[0]?.id;
			console.log('set audio input to ' + audioInputSelectedId.value)
		}

		if (!videoInputSelectedId.value) {
			videoInputSelectedId.value = props.meetingHandler.videoInputs[0]?.id;
		}

		if (!audioOutputSelectedId.value) {
			audioOutputSelectedId.value = props.meetingHandler.audioOutputs[0]?.id;
		}
	}

	function audioConstraints() {
		if (audioInputSelectedId.value) {
			return {
				deviceId: audioInputSelectedId.value,
			} as MediaTrackConstraints;
		}
	}
	function videoConstraints() {
		if (audioInputSelectedId.value) {
			const constraints: MediaTrackConstraints = {
				deviceId: videoInputSelectedId.value,
			};

			return constraints;
		}
	}

	async function joinCall() {
		if(props.meetingHandler.shouldUseGateway()){
			let url = Array.isArray(identity.identityServiceUrl.length) ? identity.identityServiceUrl[0] : identity.identityServiceUrl as string;
			HttpClient.gatewayUrl = `https://${new URL(url).host}/gateway`;
			console.log('setting ls2 gateway for waiting room to ' + HttpClient.gatewayUrl);
		}
		if(!isMediaReady.value){
			console.warn('Media is not ready, cannot join call')
			return
		}

		if(!props.meetingHandler.userMedia){
			console.warn('Canvas is not ready, cannot join call')
			return
		}

		if (!channelValidationComplete && !isLobbyEnabled.value) {
			eventBus.emitEvent("loading", "Validating meeting info...");
		}

		if (areDevicesBlocked.value) {
			Swal.fire({
				title: "Error",
				html: permissionsMessage,
				confirmButtonText: "OK",
			});
			return;
		}

		await channelValidationTask;
		await lobbyValidationTask;

		if (!passcode.value && passcodeRequired.value) {
			passcodeError.value = true;
		} else {
			passcodeError.value = false;
		}

		if (passcodeError.value || !validChannel.value) {
			eventBus.emitEvent("loading-complete");
			return;
		}

		localStorage.setItem("Channel", JSON.stringify(callChannel.value));
		useLocalStorage().setChannelKey(callChannel.value?.ChannelKey);

		if (!userName.value) {
			console.warn("userName not set by init method.");
			userName.value = localStorage.getItem("Username") ?? "Guest";
		}

		if (isLobbyEnabled.value) {
			joinedLobby.value = true;
			await props.lobbyHandler.joinLobby({
				admittedCallback: joinRoom,
				channelKey: channelKey.value,
				channelPasscode: passcode.value,
				deniedCallback: hostDenied,
				errorCallback: errorCallback,
				identity: identity,
				isHost: useLocalStorage().getChannel()?.MeetingOwner ?? false,
				userName: userName.value,
				meetingHandler: props.meetingHandler,
				webhookTimer: 30 * 1000,
			});
		} else {
			joinRoom();
		}
	}

	function errorCallback() {
		joinedLobby.value = false;
	}

	function hostDenied() {
		Swal.fire({
			position: "top-end",
			text: "Entry to the meeting has been denied by the host.",
			confirmButtonText: "Close",
			showConfirmButton: true,
			toast: true,
		}).then(() => {
			window.location.href = import.meta.env.VITE_USER_SITE_URL;
		});
	}

	function joinRoom() {
		emit("join", {
			channelId: channelKey.value,
			passCode: passcode.value,
			userName: userName.value,
			audioInputDeviceId: audioInputSelectedId.value,
			audioOutputDeviceId: audioOutputSelectedId.value,
			videoInputDeviceId: videoInputSelectedId.value,
			permissionsGranted: !areDevicesBlocked.value,
			audioMuted: audioMuted.value,
			videoMuted: videoMuted.value,
			audioConstraints: audioConstraints(),
			videoConstraints: videoConstraints(),
			subject: subject.value,
			facingMode: facingMode,
			tokenUrl: tokenUrl.value,
			apiKey: apiKey.value,
			ls2Token: ls2Token.value,
			identity: identity,
			meetingRoom: meetingRoom,
			freeAccount: freeAccount.value,
			userMedia: props.meetingHandler.userMedia,
			features: features.value,
			botSocketUrl: botSocketUrl.value,
			botEnabled: botEnabled.value,
			videoBlurred: videoBlurred.value,
			virtualBackgroundEnabled: virtualBackgroundEnabled.value
		});
	}

	async function cancel() {
		if (joinedLobby.value) {
			await props.lobbyHandler.leave();
			window.location.href = import.meta.env.VITE_USER_SITE_URL;
		} else {
			window.location.href = import.meta.env.VITE_USER_SITE_URL;
		}
	}

	async function toggleAudio() {
		audioMuted.value = !audioMuted.value;
		if (audioMuted.value) {
			await userMedia.value?.muteAudio();
		} else {
			await userMedia.value?.unmuteAudio();
		}
	}

	async function toggleVideo() {
		videoMuted.value = !videoMuted.value;
		if (videoMuted.value) {
			await userMedia.value?.muteVideo();
		} else {
			await userMedia.value?.unmuteVideo();
		}
	}

	async function setVideoBlurEnabled(value: boolean) {
		virtualBackgroundEnabled.value = false
		videoBlurred.value = value
		setBackgroundMode()
	}

	async function setVirtualBackgroundEnabled(value: boolean) {
		videoBlurred.value = false
		virtualBackgroundEnabled.value = value
		setBackgroundMode()
	}

	function setBackgroundMode(){
		if(!videoBlurred.value && !virtualBackgroundEnabled.value){
			props.meetingHandler.backgroundMode = ''
		}else if(videoBlurred.value){
			props.meetingHandler.backgroundMode = 'blur'
		}else if(virtualBackgroundEnabled.value){
			props.meetingHandler.backgroundMode = 'virtual'
		}else{
			props.meetingHandler.backgroundMode = ''
		}
	}

	onMounted(async () => {
		Log.level = import.meta.env.VITE_EDEN_LOG_LEVEL;
		globalThis.__edenLogs = Log;
		console.debug(`SDK Version: ${version}`);
		globalThis.__sdkVersion = version;

		
		await checkAuthentication();

		await initializeAndValidateChannel();

		let watcher = () =>{
			if(props.meetingHandler.prejoinCheckCompleted){
				completeMount()
			}else{
				setTimeout(watcher, 100);
			}
		}
		watcher()
	});

	async function completeMount() {
		await props.meetingHandler.initializeDeviceManager()

		// create the user media instance
		createUserMedia()
		
		// apply the default values into the local props
		await applyDefaultDevices();

		// apply user preferences on top of the defaults
		await applyUserPreferences();

		// watch the user preferences, apply them to the user media instance
		await applyAndWatchSelectedAudioAndVideoInput();

		// start up a camera and mic using the selections made above
		await startUserMedia();

		// ensure we are 100% in sync
		doubleCheckSelectionsAndPreview();

		//TODO: should really be on the meeting handler...
		//await startUserMedia();
		//await getStream();
		if(!useCanvas()){
			// if we're not using the canvas, we can allow join
			// if we ARE using the canvas, we wait for that event to fire
			console.log('not using canvas, allow join')

			props.meetingHandler.userMedia = userMedia.value!;
			isMediaReady.value = true;
		}else{
			console.log('using canvas, waiting for ready')
		}
		
		

		if (useHelpers().isSafari()) {
			window.addEventListener("beforeunload", (e) => {
				stopTracks();
			});
		}

		useEventBus().onEvent("leave-call", () => {
			stopTracks();
		});
	}
	
	
	async function checkAuthentication() {
		try {
			const validationResponse = await authenticationService.validateAuthentication();

			if (validationResponse.Error) {
				throw Error(validationResponse.Message?.Reason ?? "Auth validation failed");
			}

			await init(validationResponse);
		} catch (ex: any) {
			console.error(`Failed to validate authentication`, ex);
			appInsights.trackException(
				{
					exception: ex,
					id: "AuthValidationFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("AuthValidationFailed", ex.message)
			);

			authenticationService.clearAuthenticationCookies();
			await guestAuthentication();
			return;
		}
	}

	async function init(authValidationResponse: AuthenticationValidationResponse) {
		useEventBus().emitEvent("full-auth-validated", authValidationResponse);
		tokenUrl.value = authValidationResponse.TokenUrl;
		apiKey.value = authValidationResponse.ApiKey;
		ls2Token.value = authValidationResponse.Token;
		botSocketUrl.value = authValidationResponse.BotSocketUrl;
		botEnabled.value = authValidationResponse.BotEnabled;
		userName.value = authValidationResponse.DisplayName ?? localStorage.getItem("Username") ?? "Guest";
		freeAccount.value = authValidationResponse.FreeAccount;
		userNameDisabled.value = false;
		features.value = authValidationResponse.Features;
		
		console.log('bot enabled?', authValidationResponse.BotEnabled)
		if(props.meetingHandler){
			props.meetingHandler.botEnabled = authValidationResponse.BotEnabled || false;
		}

		try {
			if (ls2Token.value) {
				try {
					identity = new Identity({
						type: "externalToken",
						apiKey: apiKey.value,
						identityServiceUrl: tokenUrl.value,
						externalToken: ls2Token.value,
					});
					await identity.token();
				} catch (err) {
					if (err instanceof BadRequestError && err.message.indexOf("UserAlreadyTakenException") != -1) {
						identity = await authenticationService.associateTenantUser(
							authValidationResponse.Email,
							authValidationResponse.DisplayName
						);
					} else {
						throw err;
					}
				}
			} else {
				identity = new Identity({
					type: "anonymous",
					apiKey: apiKey.value,
					identityServiceUrl: tokenUrl.value,
					displayName: userName.value,
				});
			}
		} catch (ex: any) {
			console.error(`Failed to validate authentication`, ex);
			appInsights.trackException(
				{
					exception: ex,
					id: "LS2AuthValidationFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("LS2AuthValidationFailed", ex.message)
			);

			Swal.fire({
				title: "Error",
				text: "An unexpected error occurred while verifying authentication. Please try again.",
				confirmButtonText: "Close",
			});
		}
	}

	async function guestAuthentication() {
		if (useHelpers().isAuthenticatedGuest()) {
			// Try to use existing guest authentication
			try {
				const validationResponse = await authenticationService.validateAuthentication();

				if (validationResponse.Error) {
					throw Error(validationResponse.Message?.Reason ?? "Auth validation failed");
				}

				await init(validationResponse);
				return;
			} catch (ex: any) {
				console.error(`Failed to validate authentication`, ex);
				appInsights.trackException(
					{
						exception: ex,
						id: "AuthValidationFailed",
						severityLevel: SeverityLevel.Critical,
					},
					useHelpers().getLoggingProperties("AuthValidationFailed", ex.message)
				);

				authenticationService.clearAuthenticationCookies();
				await guestAuthentication();
				return;
			}
		}

		// Create new guest authentication
		const fingerprintTask = await authenticationService.getFingerprintPromise();
		const fingerprint = await fingerprintTask.get();
		const request: SignInRequest = {
			AccountType: "ANONYMOUS",
			UserName: userName.value || "Guest",
			SharedFingerprint: {
				WebFingerprint: fingerprint,
			},
		};

		const response = await authenticationService.signIn(request);

		if (!response.isValid()) {
			authenticationService.clearAuthenticationCookies();
			const error: Error = {
				name: "SOAuthenticationError",
				message: "Guest authentication failed",
			};

			appInsights.trackException(
				{
					exception: error,
					id: "AuthValidationFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties(error.name, error.message)
			);

			Swal.fire({
				title: "Error",
				text: "An unexpected error occurred during guest authentication. Please try again.",
				confirmButtonText: "Close",
			}).then(() => {
				window.location.reload();
			});
		}

		const validationResponse = await authenticationService.validateAuthentication();

		if (validationResponse.Error) {
			const error: Error = {
				name: "SOAuthenticationError",
				message: "Guest authentication validation failed",
			};

			appInsights.trackException(
				{
					exception: error,
					id: "AuthValidationFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties(error.name, error.message)
			);

			Swal.fire({
				title: "Error",
				text: "An unexpected error occurred validating your guest authentication. Please try again.",
				confirmButtonText: "Close",
			}).then(() => {
				window.location.reload();
			});
		}

		await init(validationResponse);
	}

	async function initializeAndValidateChannel() {
		const params = getRouteParams();
		const key = params["channelkey"] || '';
		const password = params["passcode"];
		channelKey.value = key;

		try {
			
			if(!key || !key.trim()){
				meetingBlankError.value = true
				return
			}
			appInsights.startTrackEvent("getChannelAsync");
			const performanceStartChannel = performance.now();
			const channel = await getChannelByKey(key);
			const timeToGetChannel = Math.round(performance.now() - performanceStartChannel);
			appInsights.trackMetric(
				{
					name: `getChannelAsync`,
					average: timeToGetChannel,
				},
				useHelpers().getLoggingProperties()
			);
			appInsights.stopTrackEvent("getChannelAsync");
			let timeToGetRoom = 0;

			if (channel && channel.ChannelId) {
				blurEnabled.value = channel.BlurEnabled && useHelpers().isVideoBlurSupported();
				console.log('Blur support:', blurEnabled.value, '; Enabled on channel? ', channel.BlurEnabled)

				if(channel.ClearDevices) {
					console.log('Clearing stored device information.');
					localStorage.removeItem('audioInput');
					localStorage.removeItem('audioOutput');
					localStorage.removeItem('videoInput');
				}

				/*if(blurEnabled.value){
					// background job - load up 
					window.setTimeout(function(){
						VideoBlurHelper.preload()
					},1000)
				}*/

				channelName.value = (channel.MeetSubject != "" ? channel.MeetSubject : channel.ChannelName) ?? ''
				if (channel.ChannelStatus == cancelled) {
					Swal.fire({
						title: "This meeting has been cancelled",
						text: "The meeting has been cancelled.",

						confirmButtonText: "Please contact the meeting organizer for more information.",
					}).then(() => {
						window.location = import.meta.env.VITE_USER_SITE_URL;
					});

					return;
				}

				const apiClient = new ApiClient({ identity: identity });
				const performanceStartRoom = performance.now();
				appInsights.startTrackEvent("getRoomInfoByKey");
				const roomResponse = await apiClient.getRoomInfoByKey(key);
				timeToGetRoom = Math.round(performance.now() - performanceStartRoom);
				appInsights.trackMetric(
					{
						name: `getRoomInfoByKey`,
						average: timeToGetRoom,
					},
					useHelpers().getLoggingProperties()
				);
				appInsights.stopTrackEvent("getRoomInfoByKey");
				meetingRoom.value = roomResponse.value;
				props.meetingHandler.setRoomInfo(roomResponse.value);
				console.log(roomResponse)

				if (!roomResponse.errors && meetingRoom && meetingRoom.value) {
					validChannel.value = true;
					passcodeRequired.value = meetingRoom.value.hasPasscode;
					passcode.value = password;
					subject.value = (channel.MeetSubject || channel.ChannelName) ?? "";
					callChannel.value = channel;

					if (meetingRoom.value?.numbers?.length) {
						useLocalStorage().setDialInNumbers(key, meetingRoom.value.numbers);
					}

					if (subject.value != "") {
						document.title = `${document.title} | ${subject.value}`;
					}

					initializeTenantLogo(channel);
					if (channel.MeetingOwner) {
						lobbyValidationTask = await authenticationService.createLobbyRoomIfNeeded(
							apiClient,
							key + "-LOBBY",
							passcode.value
						);
					}
				} else {
					const properties = useHelpers().getLoggingProperties(
						"LS2 Room Error",
						"Failed to retrieve LS2 room"
					);
					(properties as any).ChannelKey = channelKey.value;

					appInsights.trackException(
						{
							exception: new Error("Failed to retrieve LS2 room"),
							severityLevel: SeverityLevel.Critical,
						},
						properties
					);

					meetingIdError1.value = true;
				}

				isLobbyEnabled.value = channel.IsLobbyEnabled;

				// can we invite via sms here?
				props.meetingHandler.canInviteViaSms = true; //channel.EnableSMSInvites;
				props.meetingHandler.canSendWebhook = channel.EnableWaitingroomWebhook;
			} else {
				meetingIdError2.value = true;
			}
		} catch (ex: any) {
			console.error(`Error initializing channel: Message=${ex.message}`, ex);
			appInsights.trackException(
				{
					exception: ex,
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties(ex.name, ex.message)
			);

			meetingIdError3.value = true;
			meetingIdError3Message.value = ex.message
		}

		channelValidationComplete = true;
	}

	function initializeTenantLogo(channel: ChannelDetails) {
		if (channel.TenantLogo) {
			const logoUrl = channel.TenantLogo.SettingValue;

			if (logoUrl) {
				eventBus.emitEvent("custom-logo-updated", logoUrl);
			}
		}
	}
</script>
<style lang="scss" scoped>
	.big-btn {
		width: 120px !important;
		margin: 0 5px;

		&:first-child {
			margin-left: 10px;
		}
	}

	.preview-container {
		display: flex;
		flex-direction: column;
	}

	#videoElement,
	canvas {
		border-radius: 10px;
		max-width: 100%;
	}

	.video-controls {
		position: absolute;
		bottom: 0;
		border-radius: 0 0 10px 10px;
		background-color: rgba($color: #000000, $alpha: 0.4);
		// margin-bottom: 1px;
		left: 12px;
		right: 12px;
		padding: 10px 0;
		height: auto;
	}

	.video-controls .col-auto {
		padding: 0 4px;
	}

	.video-controls .btn {
		font-size: 18px;
	}

	.video-preview {
		width: 100%;
		height: 100%;
		position: relative;
		display: flex;
		flex: 1;
		background-color: #000;
		border-radius: 15px;
	}

	.video-preview video,
	.video-preview canvas {
		width: 100%;
		height: 100%;
		min-width: 100%;
		min-height: 100%;
		max-height: 30vh;
		border-radius: 15px;
	}
	
	.video-preview video {
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		-moz-transform: rotateY(180deg);
	}

	.back-facing {
		transform: rotateY(0deg) !important;
		-webkit-transform: rotateY(0deg) !important;
		-moz-transform: rotateY(0deg) !important;
	}

	.btn-white {
		color: white !important;
		width: 36px;
		height: 36px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 50% !important;
		padding: 0 !important;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.with-icon {
		position: relative;

		.form-select {
			padding-left: 36px;
		}

		.select-icon {
			position: absolute;
			top: 7px;
			left: 8px;
			z-index: 1;
			text-align: center;
			width: 24px;
			height:24px;
			pointer-events: none;
		}

		
	}

	.with-icon-right-outside{
		margin-right: 36px;
		
		.action-icon {
			position: absolute;
			top: 7px;
			right: 8px;
			z-index: 1;
			text-align: center;
			width: 24px;
			height:24px;
			pointer-events: none;
		}
	}

	.muted {
		color: #ff715b !important;
	}

	.blurred {
		color: #346ee0 !important;
	}

	.h4 {
		font-family: "Inter_Medium" !important;
		font-size: 22px;
	}

	.h6 {
		font-size: 14px;
	}

	select,
	input,
	button,
	small {
		font-family: Inter_Medium !important;
	}

	select:hover,
	option:active {
		cursor: pointer;
		color: #323b4b;
		background-color: #ffd279 !important;
	}

	option {
		padding: 5px;
		font-family: "Inter_Medium";
		font-size: 16px;
	}

	.active-attendees {
		padding-top: 15px;
	}

	.active {
		background: #dfe1ef;
	}

	.btn-join,
	.btn-cancel {
		width: 100%;
		border-radius: 9px;
		height: 48px;
		font-size: 18px;
	}

	.btn-join {
		background-color: #346ee0;
		border-color: #346ee0;
	}

	.btn-cancel {
		color: #346ee0;
		background-color: #fff;
		border-color: #fff;
	}

	.btn-dismiss {
		width: 100px;
	}

	a {
		color: white;
	}

	a:hover {
		color: #ccc;
	}

	.lobby-row {
		// width: 100%;
	}

	.logo {
		filter: unset;
	}

	.terms {
		font-family: "Inter_Medium";
		font-size: 0.9rem;
	}

	.meeting-name {
		margin-top: 5px;
	}

	.form-control,
	.form-select {
		touch-action: none;
	}

	.mirror {
		transform: scale(-1, 1);
	}

	@media (min-width: 576px) {
		.lobby-container{
			max-width: none;
		}

	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.video-controls {
			padding: 4px 0;

			.btn-white {
				width: 36px;
				height: 36px;
			}
		}

		.preview-container {
			margin-bottom: 8px;
		}

		.button-container {
			margin-top: 0.25rem !important;
			margin-bottom: 1rem;
		}

		.ios-bottom-margin {
			margin-bottom: 4rem;
		}

		.lobby-row {
			flex-direction: row;
			max-height: 100vh;
		}

		.lobby-row .h4.setup{
			display: none;
		}

		.video-preview {
			overflow: hidden;
		}

		.form-control,
		.form-select {
			font-size: 16px;
			padding: 0.25rem 0.75rem;
		}

		.with-icon {
			.select-icon {
				top: 5px;
			}
		}

		.h4,
		.h6 {
			margin-bottom: 0.3rem;
		}
	}

	@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
		.button-container {
			background-color: #323b4b;
			bottom: 0;
			width: 100%;
			z-index: 1;
			margin:0px;
			padding:12px;
			padding-right:16px; /* i hate this...shouldnot be needed */
			left:0;
			position:fixed;
			border-top: 1px solid #111;
    		border-radius: 12px;
		}

		.button-container > div{
			padding-left:0;
			padding-right:0;
		}

		.lobby-row {
			
			overflow-y: scroll;
			flex-direction: unset !important;
		}
	}

	@media (min-width: 768px) and (max-width: 992px) {
		.btn-join.waiting {
			font-size: 15px;
		}
	}

	.indicator-level{
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#03cc00+0,fbff1c+47,d3a500+100 */
		background: linear-gradient(to right,  #03cc00 0%,#fbff1c 47%,#d3a500 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

	}

	.fa-pause{
		display:none;
	}
	.playing .fa-play{
		display:none;
	}
	.playing .fa-pause{
		display:block;
	}
</style>
