<template>
	<canvas
		ref="canvasElement"
		style="object-fit: contain;background-color:#434c5c"
		:class="mirrorStyle"></canvas>
	<!-- <div class="lobby-stats" v-if="videoStatsEnabled">
		<div class="stat">Video Stream Dimensions: {{ videoStreamWidth }}x{{ videoStreamHeight }}</div>
		<div class="stat">Video Stream FPS: {{ videoStreamFrameRate }}</div>
		<div class="stat">
			Canvas Stream Dimensions: {{ canvasStream?.getVideoTracks()[0]?.getSettings()?.width }}x{{
				canvasStream?.getVideoTracks()[0]?.getSettings()?.height
			}}
		</div>
		<div class="stat">Canvas Stream FPS: {{ canvasStream?.getVideoTracks()[0]?.getSettings()?.frameRate }}</div>
	</div> -->
</template>
<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import VideoBlurHelper from "@/classes/VideoBlurHelper";
	import useEventBus from "@/composables/useEventBus";
	import useHelpers from "@/composables/useHelpers";
	import { videoStatsEnabled } from "@/composables/useLocalStorage";
	import type { UserMedia } from "@liveswitch/sdk";
	import { ref, type PropType, onMounted, computed, watch, watchEffect, onUnmounted, inject, type WatchStopHandle } from "vue";
	import LiveSwitchUserMedia from "@/classes/LiveSwitchUserMedia";
	import { InjectionKeyAppInsights } from "@/composables/injectKeys";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const videoBlurred = ref(false);
	const videoElement = ref<HTMLVideoElement>();
	const canvasElement = ref<HTMLCanvasElement>();
	const canvasContext = ref<CanvasRenderingContext2D>();
	const tempCanvas = ref<HTMLCanvasElement>();
	const tempCanvasContext = ref<CanvasRenderingContext2D>();
	const canvasStream = ref<MediaStream>();
	const windowInFocus = ref(true);
	const animationInterval = ref(0);
	const videoInitializing = ref(true);
	const lastTime = ref(0);
	const isMounted = ref(true)

	let lastVideoHeight = -1
	let lastVideoWidth = -1
	let lastDrawTime : number = 0;
	let timeouts: number[] = []
	let raf = 0


	function getCurrentOrientation() {
		if(videoElement && videoElement.value){
			let el = videoElement.value
			if(el.videoHeight > el.videoWidth){
				return 'portrait'
			}else if(el.videoHeight < el.videoWidth){
				return 'landscape'
			}
		}
	}

	const props = defineProps({
		userMedia: {
			type: Object as PropType<UserMedia>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
		},
		height: {
			type: Number,
		},
		width: {
			type: Number,
		}
	});

	const emit = defineEmits<{
		(event: "videoBlur", videoBlurred: boolean): void;
		(event: "videoReady"): void;
	}>();

	const segmentVideo = function(){
		return (props.meetingHandler?.backgroundMode == 'blur' ||
			props.meetingHandler?.backgroundMode == 'virtual') && VideoBlurHelper.instance
	}

	const videoStreamHeight = function(){
		//return videoElement.value.videoHeight
		if(!videoElement.value){
			return 0
		}

		const width = videoElement.value.videoWidth
		const height = videoElement.value.videoHeight
		// see todo in this function; we really want to match the layout
		// of the canvas to the layout of the camera, NOT the layout of the device 
		if (getCurrentOrientation() === 'portrait') {
			// app is portrait, return the max value for height
			return Math.max(width, height)
		} else {
			return Math.min(width, height)
		}
	}

	const videoStreamWidth = function(){
		if(!videoElement.value){
			return 0
		}
		//return videoElement.value.videoWidth
		const width = videoElement.value.videoWidth
		const height = videoElement.value.videoHeight
		if (getCurrentOrientation() === 'portrait') {
			// app is portrait, return the min value for width
			return Math.min(width, height)
		} else {
			return Math.max(width, height)
		}
	};

	const mirrorStyle = computed(() => {
		return {
			mirror: props.userMedia?.videoFacingMode !== "environment",
			speaking: props.meetingHandler?.localUserMedia?.audioTrack?.isSpeaking,
			blurred: props.meetingHandler?.backgroundMode == 'blur'
		};
	});

	onMounted(async () => {
		console.log('mounted')
		/*videoBlurred.value = 
			props.meetingHandler.backgroundMode = 'blur' ||
			props.meetingHandler.backgroundMode = 'virtual'*/
		//VideoBlurHelper.init();
		VideoBlurHelper.instance.onResultsCallback = onSegmentationResults;
		addWindowHandlers();
		try{

			if (props.userMedia.isStarted) {
				await initializeVideo();
			} else {
				props.userMedia.started.bind(async () => {
					console.log('started')
					await initializeVideo();
				});
			}

			if (props.userMedia instanceof LiveSwitchUserMedia) {
				props.userMedia.videoTrack.streamUnbound.bind(() => {
					videoInitializing.value = true;
				});

				(props.userMedia as LiveSwitchUserMedia).streamChanged = async () => {
					await initializeVideo();
				};
			}

			// kick off drawing
			// Note this clears any previously running intervals.
			addTimeout(() => drawCanvas(0), 0);
		}catch(e){
			console.error('Error in mounted', e)
		}
	});

	onUnmounted(() => {
		try {
			//VideoBlurHelper.instance.close();
		} catch(_) {}
		
		console.log('unmounting')
		removeWindowHandlers();

		isMounted.value = false
		clearInterval(watchdog)
	});

	let visibilitychange = function(){
		if (document.hidden) {
			windowInFocus.value = false;
			// Make sure the loop has run before so that all the elements are already set up.
			// We run it here again because the loop wont run on a tab change for RAF, thus we need to run the loop once to implement an interval approach onto our looping
			addTimeout(() => drawCanvas(99), 1);
		} else {
			windowInFocus.value = true;
		}
	}

	function addWindowHandlers(): void {
		document.addEventListener("visibilitychange", visibilitychange)
	}

	function removeWindowHandlers(): void {
		document.removeEventListener("visibilitychange", visibilitychange)

		clearTimeouts()
	}

	function mapHeightAndWidth(){
		if(canvasElement.value){
			canvasElement.value.height = videoStreamHeight();
			canvasElement.value.width = videoStreamWidth();
		}
		
		if(tempCanvas.value){
			tempCanvas.value.height = videoStreamHeight();
			tempCanvas.value.width = videoStreamWidth();
		}

		if(videoElement.value){
			videoElement.value.height = videoStreamHeight();
			videoElement.value.width = videoStreamWidth();
			
		}

		lastVideoHeight = videoStreamHeight();
		lastVideoWidth = videoStreamWidth();
	}

	let emitted = false
	async function initializeVideo() {
		
		videoInitializing.value = true;

		

		// create a new video element
		videoElement.value = document.createElement("video") as HTMLVideoElement;
		//window.videoe
		videoElement.value.muted = true;
		videoElement.value.playsInline = true;
		videoElement.value.srcObject = props.userMedia.stream;
		videoElement.value.removeAttribute("autoplay");
		videoElement.value.style.position = "absolute";
		videoElement.value.style.left = "-9999999px";
		videoElement.value.style.top = "0";
		videoElement.value.play();
		//document.body.appendChild(videoElement.value); // display offscreen isn't actually needed; useful for debug though
		
		//rops.userMedia.stream.getVideoTracks()[0].getSettings().height
		tempCanvas.value = document.createElement("canvas") as HTMLCanvasElement;

		let loopCount = 0
		let loop = async () => {
			loopCount++
			if(	!videoElement.value || 
				!videoElement.value.videoHeight ||
				!canvasElement.value ||
				!tempCanvas.value){
				setTimeout(() => {
					if(loopCount >= 100 && loopCount % 100 === 0){
						//5 seconds of no elements at a 50ms loop time
						console.error('Failing to locate elements in loop to set up canvas')
					}
					if(!isMounted.value){
						// bail out if we're not mounted
						return
					}
					loop()
				}, 50);
				return
			}
			
			canvasStream.value = canvasElement.value.captureStream(15);
			videoInitializing.value = false;
			
			mapHeightAndWidth()

			

			canvasContext.value = canvasElement.value.getContext("2d", {
				willReadFrequently: true,
			} as CanvasRenderingContext2DSettings) as CanvasRenderingContext2D;

			tempCanvasContext.value = tempCanvas.value.getContext("2d", {
				willReadFrequently: true,
			} as CanvasRenderingContext2DSettings) as CanvasRenderingContext2D;
			
		};
		loop()
	}

	let watchdog = 0

	// this function should be called 1x on mount ONLY
	let clearTimeouts = function(){
		for(let i = 0; i < timeouts.length; i++){
			clearTimeout(timeouts[i])
		}
		timeouts = []
		cancelAnimationFrame(raf)
	}

	let addTimeout = function(fn: Function, timeout: number){
		clearTimeouts()
		let t = setTimeout(fn, timeout);
		timeouts.push(t)
	}

	async function drawCanvas(source: number) {
		if(!isMounted.value){
			// make sure we avoid looping forever
			// ismounted starts out as true, so we'll never hit this right away, it's just a failsafe to
			// make sure we don't loop forever after the component dies
			// ideally we would not do this, but instead render when a frame shows up for the video, but
			// that doesn't work so hot with background tabs
			return
		}
		//console.log('h:',videoElement?.value?.videoHeight)
		if(props.userMedia.stream){
			//console.log('paused', videoElement.value?.paused)
			//console.log('muted', videoElement.value?.muted)
		}

		//console.log('draw', arguments[0])
		if(lastDrawTime == 0){
			// hook up a watchdog timer
			watchdog = window.setInterval(function(){
				let timeSinceLastLoop = (new Date()).getTime() - lastDrawTime
				if(timeSinceLastLoop > 3000){
					// it's been a full three seconds since the last draw time was updated;
					// we can safely assume something is going wrong, and we should
					// re-start the loop
					// for example, if there is a tab switch, requestAnimationFrame pauses, so
					// possibly the loop dies; it would recover if they come back to the tab but who wants 
					// that experience...
					console.log('Watchdog timer hit, failsafe triggering...')
					addTimeout(() => drawCanvas(100), 0);
				}
			}, 1000)
		}
		
		try{
			//console.log('fps:', ((new Date()).getTime() - lastDrawTime))
			lastDrawTime = (new Date()).getTime()

			if (videoInitializing.value) {
				// re-loop
				addTimeout(() => drawCanvas(-1), 100);
				return;
			}

			if(videoElement && 
				videoElement.value && 
				videoElement.value.videoHeight != lastVideoHeight && 
				videoElement.value.videoHeight > 0 && lastVideoHeight > 0){
					console.log('re-initializing')
				lastVideoHeight = videoElement.value.videoHeight
				await initializeVideo()
				// re-loop
				addTimeout(() => drawCanvas(-2), 100);
				return
			}

			if (canvasContext.value && canvasElement.value && videoElement.value) {
				
				if(!emitted){
					emitted = true
					emit("videoReady");
				}

				if (segmentVideo()) {
					try {
						if(videoElement.value.videoHeight > 2 && videoElement.value.videoWidth > 2){
							//await VideoBlurHelper.instance.reset();
							await VideoBlurHelper.instance.sendImage(videoElement.value);
						}else{
							console.log('no video element to use')
						}
					} catch (err: any) {
						console.warn("Error during video background blur", err);
						appInsights.trackException(
							{
								exception: err,
								id: "MediaStreamError",
								severityLevel: SeverityLevel.Critical,
							},
							useHelpers().getLoggingProperties("BlurError", "BlurError")
						);
						VideoBlurHelper.instance.reset();
					}
				} else {
					canvasContext.value.save();
					canvasContext.value.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);
					canvasContext.value.globalCompositeOperation = "source-over";
					canvasContext.value.drawImage(
						videoElement.value,
						0,
						0,
						canvasElement.value.width,
						canvasElement.value.height
					);
					/*
					canvasContext.value.font="40px Arial";
					canvasContext.value.fillStyle = "white";
					const dt = new Date()
					canvasContext.value.fillText(dt.getMinutes() + ':' + dt.getSeconds(), canvasElement.value.width / 2, canvasElement.value.height / 2); 
					*/
				}
				
				// always reschedule the draw again
				// requestAnimationFrame where we can
				if (windowInFocus.value) {
					clearTimeouts()
					raf = requestAnimationFrame(() => drawCanvas(1));
				} else {
					addTimeout(() => drawCanvas(-3), 1000 / 15);
				}
			}else{
				// re-loop
				addTimeout(() => drawCanvas(-4), 100);
				// this should be SUPER rare, close to impossible, but we will handle it anyway
				console.log('Missing an element within the render loop. values are: ', {
					'v' : videoElement.value ? true:false,
					'cc' :  canvasContext.value ? true:false,
					'ce': canvasElement.value ? true:false,
					'tc':  tempCanvas.value ? true:false,
					'tcc': tempCanvasContext.value ? true:false,
					'mounted:': isMounted.value
				})
				
			}
		}catch(e){
			console.error(e)
			// re-loop
			addTimeout(() => drawCanvas(-5), 100);
		}
	}

	const BACKGROUND_BLUR_DISTANCE = 35
	const MASK_BLUR_DISTANCE = 12
	let count = 0
	let lastSuccessful: any = null

	async function onSegmentationResults(results: any) {
		
		if (videoElement.value && canvasContext.value && canvasElement.value && tempCanvas.value && tempCanvasContext.value) {
			try{
				
				// Draw selfie outline
				canvasContext.value.save();
				canvasContext.value.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);
				if(!results.segmentationMask){
					// bail if we have no mask
					return
				}
				// this draws a mask on the canvas where the person is on the image
				try{
					// try and draw it
					canvasContext.value.drawImage(
						results.segmentationMask,
						0,
						0,
						canvasElement.value.width,
						canvasElement.value.height
					);
					// save the last draw
					lastSuccessful = results.segmentationMask
				}catch(e){
					// on an error, if we were successful in the past, just redraw it to avoid flickering
					if(lastSuccessful){
						canvasContext.value.drawImage(
							lastSuccessful,
							0,
							0,
							canvasElement.value.width,
							canvasElement.value.height
						);
					}else{
						// we have never successfully drawn anything
						// bail out
						throw e
					}
				}

				let data = canvasContext.value.getImageData(
					0,
					0,
					canvasElement.value.width,
					canvasElement.value.height
				);
				// in theory this was supposed to kind of "smooth" the lines...doesn't seem super
				// effective though.
				//VideoBlurHelper.instance.applyBlurFilter(data, MASK_BLUR_DISTANCE, 1);
				canvasContext.value.putImageData(data, 0, 0);

				// this fills in the mask with the person
				canvasContext.value.globalCompositeOperation = "source-in";
				canvasContext.value.drawImage(results.image, 0, 0, canvasElement.value.width, canvasElement.value.height);

				// Draw the blurred background without the selfie image
				canvasContext.value.globalCompositeOperation = "destination-atop";
				if(!props.userMedia.stream.getVideoTracks()[0].enabled){
					// video track isn't enabled => just draw it, will be black
					//console.log('no track enabled, drawing on canvas of size w x h: ' + canvasElement.value.width, canvasElement.value.height);
					canvasContext.value.drawImage(videoElement.value, 0, 0, canvasElement.value.width, canvasElement.value.height);

					/*
					canvasContext.value.font="20px Arial";
					let dt = new Date()
					let txt = dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
					console.log(txt)
					canvasContext.value.fillText(txt, 4, 24);  //draw new time
					*/
					
				}else{
					switch(props.meetingHandler?.backgroundMode){
						case 'virtual':
							let img = props.meetingHandler?.virtualBackground?.getImage(getCurrentOrientation())
							if(img){
								canvasContext.value.drawImage(img, 0, 0, canvasElement.value.width, canvasElement.value.height);
							}
							break;
						case 'blur':
							VideoBlurHelper.instance.applyCustomBlur(
								canvasContext.value,
								videoElement.value,
								tempCanvas.value,
								tempCanvasContext.value,
								videoStreamWidth(),
								videoStreamHeight(),
								BACKGROUND_BLUR_DISTANCE
							);
							break;
						default:
							// default is just draw the video element
							canvasContext.value.drawImage(videoElement.value, 0, 0, canvasElement.value.width, canvasElement.value.height);
							break;
					}
				}
			}catch(e){
				console.error('Error in segmentation results', e)
			}finally{
				canvasContext.value.restore();
			}
		}else{
			console.log('Missing an element within the segementation results. values are: ', {
				'v' : videoElement.value ? true:false,
				'cc' :  canvasContext.value ? true:false,
				'ce': canvasElement.value ? true:false,
				'tc':  tempCanvas.value ? true:false,
				'tcc': tempCanvasContext.value ? true:false
			})
		}
	}

	/*function toggleVideoBlur() {
		//videoBlurred.value = !videoBlurred.value;
		props.meetingHandler.backgroundMode = props.meetingHandler.backgroundMode == 'blur' ? '' : 'blur'
		if (props.meetingHandler.backgroundMode == 'blur') {
			appInsights.trackMetric(
				{
					name: "ToggleBlurOn",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);
		} else {
			appInsights.trackMetric(
				{
					name: "ToggleBlurOff",
					average: 1,
				},
				useHelpers().getLoggingProperties()
			);
		}
		useEventBus().emitEvent("video-blur-updated", props.meetingHandler.backgroundMode == 'blur');
	}*/

	/*function toggleVideoMute() {
		if (!props.userMedia.stream.getVideoTracks()[0].enabled) {
			props.userMedia.stream.getVideoTracks()[0].enabled = true;
		} else {
			props.userMedia.stream.getVideoTracks()[0].enabled = false;
		}
	}
*/
	async function setVideoMuted(muted: boolean){
		//props.userMedia.stream.getVideoTracks()[0].enabled = !muted

		if(!muted){
			//await initializeVideo()
		}
	}

	function stop() {
		if (canvasStream.value) {
			canvasStream.value.getVideoTracks().forEach((track: MediaStreamTrack) => {
				track.stop();
			});
		}
		console.warn('stopped all the canvas stream tracks!!')

		/*if (props.userMedia) {
			props.userMedia.stop;
		}*/
	}

	defineExpose({
		//toggleVideoBlur,
		//toggleVideoMute,
		setVideoMuted,
		stop,
		videoBlurred,
		canvasStream,
	});
</script>
<style>
	canvas {
		height: 100%;
		width: 100%;
	}

	.mirror {
		-webkit-transform: scale(-1, 1); /* Safari and Chrome */
		-moz-transform: scale(-1, 1); /* Firefox */
		-ms-transform: scale(-1, 1); /* Internet Explorer */
		-o-transform: scale(-1, 1); /* Opera */
		transform: scale(-1, 1); /* Standard syntax */	
	}

	.speaking {
		border-color: #ffd279 !important;
	}

	.lobby-stats {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		color: black;
	}
</style>
